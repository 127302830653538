import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../store'
import {
	apiRequestOnboardItem,
} from '../../api/onboarding'
import { endLoading, loadingError, startLoading } from '../view'
import { setOnboardingConfig } from '../onboard'
import { isString } from 'lodash'

export async function fetchOnboardStatusById(dispatch :AppDispatch, id: string) :Promise<any>
{
	dispatch({type: 'proxy/fetchOnboardStatusById'})
	dispatch(startLoading('Loading entities'))
	
	return apiRequestOnboardItem(id)
		.then(({status, config}) =>
			dispatch(setOnboardingConfig({status, config})))
		.catch(err =>
			dispatch(loadingError(err)))
		.finally(() =>
			dispatch(endLoading()))
}
