import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import _isString from 'lodash/isString'
import {
	ProxyConfigStatus,
} from './onboard/types'
import { RootState } from './store'

export * from './onboard/types'

export interface OnboardingStatus
{
	dns :string
	proxy :string
	timestamp :number
}

export interface OnboardConfigState
{
	initialized :boolean,
	
	requestedId :string,
	validRequest :boolean,
	
	status :OnboardingStatus
	config :ProxyConfigStatus|undefined
	loaded :boolean
}

const initialState :OnboardConfigState = {
	initialized: false,
	
	requestedId: '',
	validRequest: false,
	
	status: {
		dns: 'unknown',
		proxy: 'unknown',
		timestamp: 0
	},
	config: undefined,
	loaded: false
}

const reg = /^[a-f\d]{24}$/i

const proxyConfigSlice = createSlice(
	{
		name: 'onboard',
		initialState,
		reducers: {
			initOnboarding: (state, action :PayloadAction<string>) =>
            {
				const requestedId = action.payload
				
				state.requestedId = requestedId
				state.validRequest = reg.test(requestedId)
				
				state.initialized = true
				state.loaded = false
			},
			onboardingSyncCheck: (state) =>
            {
				if(state.loaded)
				{
					const time = Date.now(),
						seconds = (time - state.status.timestamp) / 1000
					
					console.info('time diff', seconds, 'seconds')
					
					if(seconds >= 5 * 60)
					{
						console.warn('triggering reload')
						state.loaded = false
					}
				}
			},
			setOnboardingConfig: (state, action :PayloadAction<{status :OnboardingStatus, config: ProxyConfigStatus}>) =>
            {
				state.config = action.payload.config
				state.status = action.payload.status
				state.loaded = true
			},
		}
	})

// Action creators are generated for each case reducer function
export const { initOnboarding, onboardingSyncCheck, setOnboardingConfig } = proxyConfigSlice.actions
export const onboardConfigReducer = proxyConfigSlice.reducer
export const selectOnboardState = (state: RootState) => state.onboard

// ---

