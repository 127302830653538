import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
//@ts-ignore
import logger from 'redux-logger'
// ---
import { viewReducer } from './view'
import { onboardConfigReducer } from './onboard'
// ---
import { orchestratorMiddleware } from './orchestration'

export const store = configureStore({
		reducer: {
			view: viewReducer,
			onboard: onboardConfigReducer,
		},
		middleware: (getDefaultMiddleware) =>
			//@ts-ignore
			getDefaultMiddleware().concat(orchestratorMiddleware)
	})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
