import { apiUrl } from '../config'
import { OnboardingStatus, ProxyConfigStatus } from '../store/onboard'

export async function apiRequestOnboardItem(id :string)
{
	console.warn('apiRequestOnboardItem', id)
	
	const result = await fetch(`${apiUrl}/${id}`, {
		headers: {
			'Content-Type': 'application/json',
		}
	})
	
	const data = await result.json()
	
	console.log('apiRequestOnboardItem response data', data)
	
	if(data.error)
		throw new Error(data.error)
	
	return await data as {status :OnboardingStatus, config :ProxyConfigStatus}
}
