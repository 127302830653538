import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
// ---
import './index.scss' // include this before App to preserve loading order for CSS
import App from './App'
import { store } from './store/store'
// ---
import { appVisibilityChange, navStateChange } from './store/view'
import { onVisibilityChange } from './dom/visibility'
import { apiDomain } from './config'
import { onboardingSyncCheck } from './store/onboard'
// ---

onVisibilityChange((visible : boolean) =>
{
	store.dispatch(appVisibilityChange(visible))
	
	if(visible)
		store.dispatch(onboardingSyncCheck())
})

// Dispatch the official initialization action
store.dispatch({type: 'initialize'})

const container = document.getElementById('root')
const root = createRoot(container!) // createRoot(container!) if you use TypeScript
root.render(
		<BrowserRouter basename={process.env.PUBLIC_URL}>
			<Provider store={store}>
				<App />
			</Provider>
		</BrowserRouter>
)

console.info('init', apiDomain)
