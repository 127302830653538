import {
	useLocation,
	useNavigate,
} from "react-router-dom"
// ---

import { NonIdealPage } from './pages/NonIdealPage'
import { Component, Fragment, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from './store/store'
import { fetchOnboardStatusById } from './store/onboard/crud'
import {
	initOnboarding,
	OnboardConfigState,
	OnboardingStatus,
	ProxyConfigMapping,
	selectOnboardState
} from './store/onboard'
import { selectViewState } from './store/view'
import { proxyDomain } from './config'

import logo from './assets/images/ziplines-ed-logo-white.svg'
// "https://s3.us-west-1.wasabisys.com/greenfig/logo.svg"

function App()
{
	//console.warn('render App')
	
	const { view, onboarding } = useInitializeApp()
	
	if(!onboarding.initialized)
		return null
	
	if(!onboarding.validRequest)
		return <StatusScreen title="Ziplines Education Partner Service">
			You have reached the Ziplines Education Partner Onboarding home page.
			Please use the unique URL that you were provided in order to see the
			current status of your white labeling setup.
		</StatusScreen>
	
	if(view.error_message)
		return <StatusScreen title="Ziplines Education Partner Service">
			{view.error_message}
		</StatusScreen>
	
	if(view.loading)
		return <StatusScreen title="Ziplines Education Partner Service">
			Loading ...
		</StatusScreen>
	
	if(!onboarding.config)
		return <StatusScreen title="Ziplines Education Partner Service">
			Unexpected error
		</StatusScreen>
	
	const config = onboarding.config
	const status = onboarding.status
	
	//console.warn('onboarding.config', config)
	console.warn('onboarding.status', status)
	
	const first = config.mappings[0],
		last = config.mappings[config.mappings.length-1]
	
	return (
		<section className="main">
			<header className="flex">
				<img src={logo}
					 style={{width:"100px"}}
					 alt="GreeFig Onboarding"
				/>
			</header>
			<div className="body">
				<h1>How does this work?</h1>
				<p>Ziplines Education serves its branded partner marketing experiences through a secure and load
					balanced proxy server. Partners point their relevant sub-domain/s at our proxy server. Our
					proxy service will then create a sub-domain name specific SSL certificate. The proxy then
					maps all relevant URLs to our system using the partners sub-domain name.</p>
				
				<p>Create a CNAME record on the university domain pointing the designated subdomain to
					Ziplines Education’s Proxy Server <code>{proxyDomain}</code></p>
				
				<h2>Partner Configuration</h2>
				
				<div className="flex top-border middle-border row">
					<span>Partner</span> <span>{config.name}</span>
				</div>
				<div className="flex middle-border row">
					<span>Partner Domain</span> <span>{config.domain}</span>
				</div>
				<div className="flex middle-border row">
					<span>CNAME</span> <span>{proxyDomain}</span>
				</div>
				
				<DNSStatusDisplay status={status} />
				<ProxyStatusDisplay status={status} />
				
				{config.status === 'online' && config.mappings.length > 0 &&
					<>
						<p>Here is a list of all URLs configured for {config.name}</p>
						
						<h2>Partner URLs</h2>
						
						{config.mappings.map(m =>
							<div key={m.from} className={mappingClassname(m, first, last)}>
								<a href={`https://${config.domain}${m.from}`} target={`${config.domain}${m.from}`}>
									https://{config.domain}{m.from}
								</a>
							</div>
						)}
					</>
				}
				
				<p>
					If you have any questions or issues please contact us
					at <a href="mailto:domains@ziplines.com">domains@ziplines.com</a>
				</p>
			</div>
		
		</section>
	)
}

function DNSStatusDisplay({status} :{status :OnboardingStatus})
{
	const s = status
	
	if(s.proxy === 'disabled')
		return null
	
	switch(s.dns)
	{
		case 'unknown':
			return <div className="flex row status">
				<span>DNS Status</span>
				<span><b>Partner setup required</b></span>
			</div>
		case 'connected':
			return <div className="flex row status">
				<span>DNS Status</span>
				<span><strong>Connected</strong></span>
			</div>
		case 'not-connected':
			return <div className="flex row status">
				<span>DNS Status</span>
				<span><b>Incorrect DNS condfiguration</b></span>
			</div>
	}
	
	return null
}

function ProxyStatusDisplay({status} :{status :OnboardingStatus})
{
	const s = status
	
	if(s.proxy === 'disabled')
		return <div className="flex row status bottom-border">
			<span>Proxy Status</span>
			<span><b>Disabled</b></span>
		</div>
	
	if(s.dns !== 'connected')
		return <div className="flex row status bottom-border">
			<span>Proxy Status</span>
			<span><b>Pending setup</b></span>
		</div>
	
	if(s.proxy === 'online')
		return <div className="flex row status bottom-border">
			<span>Proxy Status</span>
			<span><strong>Online</strong></span>
		</div>
	
	return <div className="flex row status bottom-border">
			<span>Proxy Status</span>
			<span><strong>Pending admin review</strong></span>
		</div>
}

function StatusScreen({title, children} :{title: string, children :JSX.Element|string})
{
	return <section className="main">
		<div className="body">
			<h1>{title}</h1>
			<p>{children}</p>
		</div>
	</section>
}

function mappingClassname(m :ProxyConfigMapping, first :ProxyConfigMapping|undefined, last :ProxyConfigMapping|undefined)
{
	let def = ['flex', 'middle-border', 'row']
	
	if(m === first)
		def.push('top-border')
	
	if(m === last)
		def.push('bottom-border')
	
	return def.join(' ')
}

export default App

// ---

function useInitializeApp()
{
	const location = useLocation()
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const [formatValid, setFormatValid] = useState(false)
	const view = useAppSelector(selectViewState)
	const onboarding = useAppSelector(selectOnboardState)
	const id = window.location.pathname.substring(1)
	
	useEffect(() =>
	{
		if(id !== '' && id === onboarding.requestedId)
			return
		
		dispatch(initOnboarding(id))
	}, [onboarding, id])
	
	useEffect(() =>
	{
		if(view.loading)
			return
		
		if(!onboarding.validRequest)
			return
		
		if(!onboarding.loaded)
		{
			console.warn('Loading onboarding status')
			fetchOnboardStatusById(dispatch, id)
		}
	
	}, [navigate, location, onboarding, id, view.visible])
	
	return {
		view,
		onboarding
	}
}
